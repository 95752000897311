// Gatsby requirements
import React from 'react'
import { Link } from 'gatsby'

// Styles
import * as CSS from './Footer.module.scss'

const Footer = () =>  {

    return (
        <footer className={CSS.footer}>
            <Link to="/" aria-label="Link to homepage">
                <img loading="eager" src="/butterfly-logo-footer.svg" height="48" width="198" alt="Butterfly London logo"/>
            </Link>

            <div>
                <Link to="/cookie-policy/">Cookie Policy</Link>
                {/* <Link to="/terms-and-conditions/">Terms and Conditions</Link> */}
                <Link to="/privacy-policy/">Privacy Policy</Link>
            </div>

            <p>© 2022 Butterfly.London. All Rights Reserved</p>
        </footer>
    )
}

export default Footer