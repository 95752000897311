// extracted by mini-css-extract-plugin
export var header = "Header-module--header--a-VuN";
export var largeLogo = "Header-module--largeLogo--td3W6";
export var logo = "Header-module--logo--T2sMs";
export var menu = "Header-module--menu--7Rj6X";
export var menuActive = "Header-module--menuActive--2oLrZ";
export var menuButton = "Header-module--menuButton--xzKyQ";
export var menuInformation = "Header-module--menuInformation--3i7XZ";
export var menuLeft = "Header-module--menuLeft--XDxCg";
export var menuRight = "Header-module--menuRight--b1PVJ";
export var search = "Header-module--search--of8dH";
export var searchActive = "Header-module--searchActive--t-CID";
export var searchButton = "Header-module--searchButton--rvpF2";
export var skip = "Header-module--skip--43XJf";
export var wrapper = "Header-module--wrapper--d2q1l";