import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ( data ) => {

    return (

        <>

        <Helmet>
            <html lang="en-gb" />
            <meta property="og:locale" content="en-gb" />
            <meta property="og:site_name" content="Butterfly" />
            <meta property="twitter:card" content="summary_large_image" />

            {data.type && <meta property="og:type" content={data.type} /> }

            {data.thumbnail && <meta property="og:image" content={data.thumbnail} /> }
                {data.thumbnail === undefined && <meta property="og:image" content="/butterfly-thumbnail.png" />}
            {data.thumbnail && <meta property="twitter:image" content={data.thumbnail} /> }
                {data.thumbnail === undefined && <meta property="twitter:image" content="/butterfly-thumbnail.png" /> }

            {data.description && <meta name="description" content={data.description} /> }
            {data.description && <meta property="og:description" content={data.description} /> }
            {data.description && <meta property="twitter:description" content={data.description} />}

            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ed2b78" />
            <meta name="msapplication-TileColor" content="#ed2b78" />
            <meta name="theme-color" content="#ffffff" />

        </Helmet>

        {data.url === '/' ? 
            <Helmet>
                {data.title &&
                    <title>{data.title}</title>
                }
                <meta property="og:url" content="https://butterfly.london/" />
                <meta property="twitter:url" content="https://butterfly.london/" />
                {data.metaTitle !== null ? <meta name="title" content={`${data.metaTitle}`} /> 
                    : <meta name="title" content={`${data.title}`} />  }
                {data.metaTitle !== null ? <meta property="og:title" content={`${data.metaTitle}`} /> 
                    : <meta property="og:title" content={`${data.title}`} /> }
                {data.metaTitle !== null ? <meta property="twitter:title" content={`${data.metaTitle}`} /> 
                    : <meta property="twitter:title" content={`${data.title}`} /> }
            </Helmet>
            :
            <Helmet>
                {data.title &&
                    <title>{data.title} - Butterfly</title>
                }
                {data.url && <meta property="og:url" content={`https://butterfly.london/${data.url}`} /> }
                {data.url && <meta property="twitter:url" content={`https://butterfly.london/${data.url}`} /> }
                {data.metaTitle !== null ? <meta name="title" content={`${data.metaTitle} - Butterfly`} /> 
                    : <meta name="title" content={`${data.title} - Butterfly`} />  }
                {data.metaTitle !== null ? <meta property="og:title" content={`${data.metaTitle} - Butterfly`} /> 
                    : <meta property="og:title" content={`${data.title} - Butterfly`} /> }
                {data.metaTitle !== null ? <meta property="twitter:title" content={`${data.metaTitle} - Butterfly`} /> 
                    : <meta property="twitter:title" content={`${data.title} - Butterfly`} /> }
            </Helmet>
        }

        </>
    )
}

export default Seo