// Gatsby requirements
import React from 'react'

// Components
import Footer from './Footer'
import Header from './Header'

// Styles
import '../../styles/global.scss'

const Layout = (props) => {


    return(
        <>
            <Header url={props.url} />
                {props.children}
            <Footer/>
        </>
    )
}

export default Layout