// Gatsby requirements
import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from 'gatsby'

// Components
import RichText from "../RichTextRenderer"
import Search from "../Search"

// Styles
import * as CSS from './Header.module.scss'

const Header = ({ url }) =>  {

    const data = useStaticQuery(graphql`
        query {
            contentfulHeader {
                headerLinks {
                    references {
                        __typename
                        ...FragmentRichTextPage
                    }
                    raw
                }
            }
        }
    `)

    // Logic for opening the menu
    const [isMenuOpen, setMenuOpen] = useState(false)

    // Logic for opening the search
    const [isSearchOpen, setSearchOpen] = useState(false)
 
    // Menu opening fade and scroll disabling on mobile
    useEffect(() => {
        if (isMenuOpen === true) {
            document.body.classList.add("disable-scrolling");
            document.getElementById('menu').style.visibility = 'visible';
        } else {
            document.body.classList.remove("disable-scrolling");
            setTimeout(() => {  document.getElementById('menu').style.visibility = 'hidden' }, 300);
        }

        if (isSearchOpen === true) {
            document.body.classList.add("disable-scrolling");
            document.getElementById('search').style.visibility = 'visible';
        } else {
            document.body.classList.remove("disable-scrolling");
            setTimeout(() => {  document.getElementById('search').style.visibility = 'hidden' }, 300);
        }
    }, [isMenuOpen, isSearchOpen])

    // Add sublist icons and click handler
    useEffect(() => {
        var nums = document.getElementById("navigation");
        var listItem = nums.getElementsByTagName("li");
        for (let item of listItem) {
            if (item.childElementCount > 1) {
                item.firstChild.classList.add("has-sublist");
                item.firstChild.addEventListener('click', function() {
                    this.parentElement.classList.toggle('sublist-active');
                })
            }
        }
    }, [])

    const menuClick = () => {
        setTimeout(() => {  window.history.pushState("", document.title, window.location.pathname + window.location.search); }, 150);
    };

    // Remove the #1 from URL after scrolling to top
    const goToTop = () => {
        setTimeout(() => {  window.history.pushState("", document.title, window.location.pathname + window.location.search); }, 150);
    };

    return(
        <header 
            className={`
                ${CSS.header} 
                ${isMenuOpen === true ? CSS.menuActive: ''}
                ${isSearchOpen === true ? CSS.searchActive: ''}
            `}
        >
            
            <div className={CSS.wrapper}>

                <a className={CSS.skip} href="#content">Skip to content</a>
                

                {/* Site logo */}
                {url === 'home' ?
                    <a href="#1" onClick={goToTop} className={CSS.logo} aria-label="Link to homepage">
                        <img loading="eager" src="/butterfly-logo.svg" height="48" width="198" alt="Butterfly London logo"/>
                    </a>
                    :
                    <Link to="/" className={CSS.logo} aria-label="Link to homepage">
                        <img loading="eager" src="/butterfly-logo.svg" height="48" width="198" alt="Butterfly London logo"/>
                    </Link>
                }


                {/* Button that opens search */}
                <button
                    aria-haspopup="true"
                    aria-label="Open search"
                    aria-controls="search"
                    className={CSS.searchButton}
                    aria-expanded={isSearchOpen === true ? "true" : "false"}
                    onClick={() =>
                        isSearchOpen === true
                        ? setSearchOpen(false)
                        : setSearchOpen(true)
                    }
                    >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.82609 16.6522C13.1483 16.6522 16.6522 13.1483 16.6522 8.82609C16.6522 4.50386 13.1483 1 8.82609 1C4.50386 1 1 4.50386 1 8.82609C1 13.1483 4.50386 16.6522 8.82609 16.6522Z" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M19.0001 19.0001L14.36 14.36" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>


                {/* Button that opens menu */}
                <button
                    aria-haspopup="true"
                    aria-label="Open main menu"
                    aria-controls="menu"
                    className={CSS.menuButton}
                    aria-expanded={isMenuOpen === true ? "true" : "false"}
                    onClick={() =>
                        isMenuOpen === true
                        ? setMenuOpen(false)
                        : setMenuOpen(true)
                    }
                    >
                        <svg aria-hidden="true" viewBox="0 0 100 80" fill="#fff" width="30" height="30">
                            <rect width="100" height="11" rx="8"></rect>
                            <rect y="30" width="100" height="11" rx="8"></rect>
                        </svg>
                </button>


                {/* The search popup */}
                <div id="search" className={`${CSS.search} ${CSS.menuActive}`}>
                    <Search indices={[{ name: `Pages`, title: `Pages` }]} />
                    <button
                        aria-haspopup="true"
                        aria-label="Close search"
                        aria-controls="search"
                        className={CSS.menuButton}
                        aria-expanded={isSearchOpen === true ? "true" : "false"}
                        onClick={() =>
                            isSearchOpen === true
                            ? setSearchOpen(false)
                            : setSearchOpen(true)
                        }
                        >
                            <svg aria-hidden="true" viewBox="0 0 100 80" fill="#fff" width="30" height="30">
                                <rect width="100" height="11" rx="8"></rect>
                                <rect y="30" width="100" height="11" rx="8"></rect>
                            </svg>
                    </button>
                </div>


                {/* The menu popup */}
                <div id="menu" className={CSS.menu}>
                    <nav id="navigation" className={CSS.menuRight}>
                        {/* <RichText content={data.contentfulHeader.headerLinks} /> */}
                        <ul>
                            <li><p><a aria-current="page" href="/">Home</a></p></li>
                            <li><p class="has-sublist"><a href="/what-we-do/">What We Do</a></p>
                                <ul>
                                    <li><p><a href="/create/">Create</a></p></li>
                                    <li><p><a href="/build/">Build</a></p></li>
                                    <li><p><a href="/host/">Host</a></p></li>
                                    <li><p><a href="/digital-marketing/">Market</a></p></li>
                                </ul>
                            </li>
                            <li><p><a href="/#4" onClick={() => setMenuOpen(false)}>Contact Us</a></p></li>
                        </ul>
                    </nav>
                    <div className={CSS.menuLeft}>
                        <div className={CSS.largeLogo}>
                            <img src="/butterfly-logo-icon.svg" aria-hidden="true" alt=""/>
                        </div>
                        <div className={CSS.menuInformation}>
                            <div>
                                <h3>Work With <b>Us</b></h3>
                                <p>
                                    <a href="#4" onClick={() => setMenuOpen(false)}>Contact us</a>
                                </p>
                            </div>
                            <div>
                                <h3>Follow <b>Us</b></h3>
                                {/* <p>
                                    <a href="/#">Facebook</a><br/>
                                    <a href="/#">LinkedIn</a><br/>
                                    <a href="/#">Twitter</a>
                                </p> */}
                                <p>Coming soon</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        </header>
    )
}

export default Header