// Gatsby requirements
import React from "react"
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

// Components


// This component renders the Contentful rich text's own RAW format into HTML.
// It should be used whenever rich text is being used, as it enables the use of images
  // and other attachment in the text.

const RichText = ({ content }) => {

  const options = {
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderNode: {


      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        switch (node?.data?.target?.internal?.type) {
          case "ContentfulArticle":
            return (
              <Link to={`/articles/${node?.data?.target?.url}/`}>{children}</Link>
            )
          case "ContentfulPage":
            return (
              <Link to={`/${node?.data?.target?.url === 'home' ? '' : node?.data?.target?.url + '/'}`}>{children}</Link>
            )
          default:
            break
        }
      },


      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        switch (node?.data?.target?.__typename) {
          case "ContentfulAsset":
            return (
              <a href={node?.data?.target?.file?.url}>{children}</a>
            )
          default:
            break
        }
      },


      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        switch (node?.data?.target?.__typename) {
          case "ContentfulItemButton":
            return (
              <>
                {/* Regular link */}
                {node?.data?.target?.linkUrl &&
                  <a
                    href={node?.data?.target?.linkUrl}
                    id={node?.data?.target?.buttonId}
                    className={`
                      ${node?.data?.target?.style === 'Red text with dark background' ? 'button-red-text' : 'button' }
                    `}
                  >
                    {node?.data?.target?.buttonText}
                  </a>
                }

                {/* Internal link */}
                {node?.data?.target?.linkInternal?.url &&
                  <Link 
                    to={`${node?.data?.target?.linkInternal?.internal?.type === 'ContentfulPage' ? node?.data?.target?.linkInternal?.url === 'home' ? '/' : `/${node?.data?.target?.linkInternal?.url}/` : ''}${node?.data?.target?.linkInternal?.internal?.type === 'ContentfulArticle' ? `/articles/${node?.data?.target?.linkInternal?.url}/` : ''}`}
                    className={`
                      ${node?.data?.target?.style === 'Red text with dark background' ? 'button-red-text' : 'button' }
                    `}
                    id={node?.data?.target?.buttonId}
                  >
                    {node?.data?.target?.buttonText}
                  </Link>
                }

                {/* File link */}
                {node?.data?.target?.linkFile?.file?.url &&
                  <a
                    href={node?.data?.target?.linkFile?.file?.url}
                    id={node?.data?.target?.buttonId}
                    className={`
                      ${node?.data?.target?.style === 'Red text with dark background' ? 'button-red-text' : 'button' }
                    `}
                  >
                    {node?.data?.target?.buttonText}
                  </a>
                }
              </>
            )
          default:
            break
        }
      },


      [BLOCKS.EMBEDDED_ASSET]: node => {
        switch (node?.data?.target?.file?.contentType) {
          case "image/jpeg":
          case "image/png":
            const image = getImage(node?.data?.target?.gatsbyImageData)
            const title = node?.data?.target?.title;
            return (
              <>
                {title !== null && title !== "" ?
                  <figure>
                      <GatsbyImage image={image} loading="lazy" alt={node.data?.target?.description} />
                      <figcaption>{title}</figcaption>
                  </figure>
                  :
                  <GatsbyImage image={image} loading="lazy" alt={node.data?.target?.description} />
                }
              </>
            )
          case "image/svg+xml":
            return (
              <img
                src={node.data?.target?.file.url}
                alt={node.data?.target?.description}
                loading="lazy"
              />
            )
          case "image/gif":
            return (
              <img
                src={node.data?.target?.file?.url}
                alt={node.data?.target?.description}
                loading="lazy"
              />
            )
          case "video/mp4":
            return <video src={node?.data?.target?.file?.url} controls></video>
          default:
            break
        }
      },
    },
  }

  return renderRichText(content, options)
}

export default RichText
