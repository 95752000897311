import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className}>
      <input
        className="SearchInput"
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
      {/* <svg className="magnifying-glass-search" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.82609 16.6522C13.1483 16.6522 16.6522 13.1483 16.6522 8.82609C16.6522 4.50386 13.1483 1 8.82609 1C4.50386 1 1 4.50386 1 8.82609C1 13.1483 4.50386 16.6522 8.82609 16.6522Z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.0001 19.0001L14.36 14.36" stroke-linecap="round" stroke-linejoin="round">
        </path>
      </svg> */}
    </form>
  )
)