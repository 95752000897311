import styled, { css } from "styled-components"
import SearchResult from "./search-result"

const Popover = css`
  max-height: 80vh;
  overflow: auto;
  position: absolute;
  z-index: 2;
  right: 1.4em;
  top: calc(100% - 0.2em);
  margin-top: 0.5em;
  width: 100%;
  max-width: calc(100% - 0.5em);
  padding: 1em;
  font-size: 1.2em;

  .Flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5em;

    img {
      height: 1.7em;
    }
  }
`

export default styled(SearchResult)`
  display: ${props => (props.show ? `block` : `none`)};
  ${Popover}

  .HitCount {
    display: flex;
    justify-content: flex-end;
    opacity: 0.9;
    font-size: 0.95em;
  }

  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
      display: block;
    }

    li.ais-Hits-item {
      margin-bottom: 0;

      a {
        color: ${({ theme }) => theme.foreground};
        &:hover {
          color: var(--red);
        }
      }
    }
  }
`