import styled from "styled-components"
import SearchBox from "./search-box"

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  padding: 0 0 0.5em 0;
  margin: 0;
  border: unset;
  border-bottom: 1px solid #FFFFFF;
  border-radius: 0;

  .SearchInput {
    outline: none;
    color: #fff;
    border: none;
    background-color: transparent;
    font-size: 2em;
    width: 100%;
    cursor: text;
    font-family: 'Sora';
    font-weight: 700;
  }

  .SearchIcon {
    width: 1em;
    margin: 0.3em;
    pointer-events: none;
  }

  .magnifying-glass-search {
    position: absolute;
    right: 0;
    bottom: 1em;
    height: 1.5em;
    width: 1.5em;
    stroke: #fff;
  }

  @media only screen and (max-width: 65rem) {
    margin: 1em 1.4em -1em 0;
    flex-direction: row;
    position: relative;
    padding-left: 2.5em;

    .SearchIcon {
        position: absolute;
        left: 0.4em;
        top: 0.27em;
    }
  }

  @media only screen and (max-width: 35rem) {
    padding-left: 0;
  }
`