import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Hits,
  Index,
} from "react-instantsearch-dom"

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} {hitCount !== 1 ? `results` : `result`}
    </div>
  ) : <div className="HitCount">No results</div>
})

const PageHit = ({ hit }) => (
  <div>
    <Link to={hit.url === 'home' ? '/' : `/${hit.url}/`}>
        {hit.title}
    </Link>
  </div>
)

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <div className="Flex">
      <HitCount />
    </div>
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
)

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult